import classNames from 'classnames';
import React from 'react';
import './AdoptechTooltip.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const tooltipClassName = 'adoptech-tooltip';

export enum AdoptechTooltipVariant {
  Black, // default
  Orange,
  Large,
}

export const AdoptechTooltip: React.FC<{
  text: string;
  identifier: string;
  children: JSX.Element;
  showTooltip: boolean;
  placement?: 'bottom' | 'top' | 'top-start' | 'right';
  variant?: AdoptechTooltipVariant.Orange | AdoptechTooltipVariant.Large;
}> = ({
  text,
  identifier,
  children,
  showTooltip,
  placement = 'top',
  variant,
}) => {
  if (!showTooltip) return children;
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip
          className={classNames(tooltipClassName, {
            [`${tooltipClassName}--large`]:
              variant === AdoptechTooltipVariant.Large,
            [`${tooltipClassName}--orange`]:
              variant === AdoptechTooltipVariant.Orange,
          })}
          id={identifier}
        >
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};
